import Cookies from 'js-cookie';
import {
	ICancelConsultationResponse,
	IPaymentInfo,
	IPaystackPayment,
	IStripePayment,
	IVerifyPaymentRequest,
} from '../../api/types';
import { Container, Stack } from '@mantine/core';
import { useState, useLayoutEffect, useEffect } from 'react';
import { useGuardedNavigation } from '../navigation';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { routes } from '../../api/routes';
import { useTranslation } from 'react-i18next';
import PaystackPayment from '../../components/payment/paystack/paystack-payment';
import WalletPayment from '../../components/payment/wallet/wallet-payment';
import LoadingIndicator from '../../components/loading-indicator';
import { showNotification, NotificationType } from '../../utils/notification';
import axios from 'axios';
import { env } from '../../utils/env';
import { useConsultation } from '../../hooks/use-consultation';

import { monoBaseApi } from '../../api';

const persistPaymentInfoToCookies = (paymentInfo: any) => {
	Cookies.set('stripe_payment_info', JSON.stringify(paymentInfo));
};

export const processStripePayment = async (stripe: IStripePayment) => {
	try {
		const response = await axios.post(
			`${env.VITE_STRIPE_API_URL}/api/Stripe/process`,
			stripe
		);

		if (response.data.isSuccess == true) {
			persistPaymentInfoToCookies(response.data);
			window.location.href = response.data.checkoutUrl;
		} else {
			persistPaymentInfoToCookies(response.data);
		}
	} catch (error) {
		console.error('Error processing Stripe payment:', error);
		return 'failed';
	}
};

export const Payment = () => {
	const [verify, setVerify] = useState(false);
	const { navigate } = useGuardedNavigation();
	const { t } = useTranslation(['default']);
	const queryClient = useQueryClient();

	const paymentInfo = JSON.parse(
		Cookies.get('payment_info') as string
	) as IPaymentInfo;

	useEffect(() => {
		const stripePaymentInfoCookie = Cookies.get('stripe_payment_info');

		if (stripePaymentInfoCookie) {
			const stripePaymentInfo = JSON.parse(stripePaymentInfoCookie);
			if (stripePaymentInfo.isSuccess) {
				navigate('waitingroom');
			} else {
				showNotification(NotificationType.ApiError, t);
				navigate('home');
			}

			Cookies.remove('stripe_payment_info');
		}
	}, [navigate, t]);

	const { data, isLoading, isInitialLoading, isError, status } = useQuery(
		['verifyPayment'],
		() => routes.verifyPayment(GetVerifyPaymentRequest(paymentInfo)),
		{
			enabled: verify,
			cacheTime: 0,
		}
	);

	useLayoutEffect(() => {
		if (isError && !isInitialLoading) {
			showNotification(NotificationType.ApiError, t);
			Cookies.remove('payment_info');
			navigate('home');
		}

		if (typeof data?.status === 'undefined') return;

		switch (data?.status) {
			case 'success':
				navigate('home');
				break;
			case 'failed':
				navigate('home');
				break;
			default:
				showNotification(NotificationType.ApiError, t);
				break;
		}

		if (data && !isError && !isInitialLoading) {
			Cookies.remove('payment_info');
			void queryClient.invalidateQueries(['getProfile']);
		}
	}, [data, isError, isInitialLoading]);

	const handlePaymentFlow = () => {
		if (paymentInfo.paymentProvider === 'Stripe') {
			processStripePayment(paymentInfo.data as IStripePayment);
		}
	};

	useEffect(() => {
		handlePaymentFlow();
	}, [paymentInfo]);

	return (
		<Container>
			<Stack spacing={1} w="100%">
				{{
					paystack: (
						<PaystackPayment
							paystackPayment={paymentInfo.data as IPaystackPayment}
							setVerify={setVerify}
						/>
					),
					wallet: <WalletPayment setVerify={setVerify} />,
					stripe: <LoadingIndicator />,
				}[paymentInfo.paymentProvider] || <LoadingIndicator />}
			</Stack>
		</Container>
	);
};

let GetVerifyPaymentRequest: (
	paymentInfo: IPaymentInfo
) => IVerifyPaymentRequest = function (
	paymentInfo: IPaymentInfo
): IVerifyPaymentRequest {
	const verifyPaymentRequest: IVerifyPaymentRequest = {
		referenceId: paymentInfo.referenceId,
		paymentProvider: paymentInfo.paymentProvider,
	};
	return verifyPaymentRequest;
};
