import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, UseFormReturnType } from '@mantine/form';
import {
	Box,
	Button,
	Group,
	Modal,
	MultiSelect,
	Select,
	SimpleGrid,
	Stepper,
	TextInput,
	Title,
} from '@mantine/core';
import { titles } from '../../constants/titles';
import { DatePickerInput } from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';
import Container from '../../components/layout/container';
import { ListItem } from '../../components/core/list-item';
import dayjs from 'dayjs';
import { ICompleteProfileRequest } from '../../api/types';
import useProfile from '../../hooks/use-profile';
import { showNotification } from '@mantine/notifications';
import mantineConfig from '../../assets/styles/config/mantine.config.json';
import 'react-phone-input-2/lib/style.css';
import { VITE_DEFAULT_COUNTRY } from '../../utils/auth';
import PhoneInputMantine from '../../components/phoneInput/PhoneInput';

export const CompleteProfile = () => {
	const [active, setActive] = useState(0);
	const { t } = useTranslation(['default']);
	const { completeProfile, user } = useProfile();

	const form = useForm<ICompleteProfileRequest>({
		initialValues: {
			title: '',
			firstName: user?.firstName ? user.firstName : '',
			surName: user?.surName ? user.surName : '',
			dateOfBirth: null,
			gender: '',
			country: '',
			city: '',
			community: '',
			state: '',
			address: '',
			language: [],
			mobile: '',
			referallCode: '',
			currency: '',
		},

		validate: (values) => {
			if (active === 0) {
				return {
					firstName:
						values.firstName.length < 2
							? t('tr.firstname-must-have-at-least-2-letters')
							: null,
					surName:
						values.surName.length < 2
							? t('tr.surname-must-have-at-least-2-letters')
							: null,
					gender: values.gender.length < 1 ? t('tr.required') : null,
					dateOfBirth:
						values.dateOfBirth &&
						dayjs(Date.now()).diff(values.dateOfBirth) < 18
							? t('tr.you-must-be-at-least-18-years-old')
							: null,
					mobile:
						values.mobile.length < 12
							? t('tr.mobile-number-should-be-atlest-8-numbers')
							: null,
				};
			}
			if (active === 1) {
				return {
					country: values.country.length < 1 ? t('tr.required') : null,
					city: values.city.length < 1 ? t('tr.required') : null,
					language: values.language.length < 1 ? t('tr.required') : null,
					community: values.community.length < 1 ? t('tr.required') : null,
					state: values.state.length < 1 ? t('tr.required') : null,
					address: values.address.length < 1 ? t('tr.required') : null,
					mobile: values.mobile.length < 12 ? t('tr.required') : null,
				};
			}
			return {};
		},
	});

	const nextStep = async () => {
		setActive((current) => {
			if (form.validate().hasErrors) {
				return current;
			}
			return current < 3 ? current + 1 : current;
		});
		if (active >= 2 && !form.validate().hasErrors) {
			await completeProfile(form.values, {
				onSuccess: () => {
					showNotification({
						title: t('tr.notification_success-success'),
						message: t('tr.profile-updated'),
					});
				},
			});
		}
	};
	const FormKeysToTitle: Record<string, string> = {
		title: t('tr.your-title'),
		firstName: t('tr.first-name'),
		surName: t('tr.last-name'),
		dateOfBirth: t('tr.date-of-birth'),
		gender: t('tr.gender'),
		country: t('tr.country'),
		state: t('tr.state'),
		city: t('tr.city'),
		community: t('tr.community'),
		address: t('tr.address'),
		language: t('tr.language'),
		mobile: t('tr.mobile'),
	};
	const prevStep = () =>
		setActive((current) => (current > 0 ? current - 1 : current));

	const [modalOpened, setModalOpened] = useState(false);

	const handleAllowLocation = () => {
		setModalOpened(false);
	};

	return (
		<Container sx={{ marginTop: '1rem' }}>
			<Modal
				opened={modalOpened}
				onClose={() => setModalOpened(false)}
				title="Location Access"
			>
				<p>
					Please allow location access to auto-detect your country, state, and
					city.
				</p>
				<Button onClick={handleAllowLocation}>Allow Location</Button>
			</Modal>
			<Title order={2} my={4}>
				{t('tr.complete-profile').toUpperCase()}
			</Title>
			<Stepper active={active} breakpoint="sm" w="80%">
				<Stepper.Step
					sx={{
						fontWeight: 700, // Apply custom font weight
						color: 'black',
					}}
					label={t('tr.first-step').toUpperCase()}
					description={t('tr.personal-information').toUpperCase()}
				>
					<StepOne form={form} />
				</Stepper.Step>

				<Stepper.Step
					sx={{
						fontWeight: 700, // Apply custom font weight
						color: 'black',
					}}
					label={t('tr.second-step').toUpperCase()}
					description={t('tr.your-location').toUpperCase()}
				>
					<StepTwo form={form} />
				</Stepper.Step>

				<Stepper.Completed>
					<SimpleGrid
						cols={2}
						spacing="xs"
						breakpoints={[
							{ minWidth: 600, cols: 2, spacing: 'lg' },
							{ maxWidth: 600, cols: 1, spacing: 'sm' },
						]}
					>
						<ListItem
							title={FormKeysToTitle['title']}
							description={
								form.values.title.charAt(0).toUpperCase() +
								form.values.title.slice(1)
							}
						/>
						<ListItem
							title={FormKeysToTitle['firstName']}
							description={form.values.firstName}
						/>
						<ListItem
							title={FormKeysToTitle['surName']}
							description={form.values.surName}
						/>
						<ListItem
							title={FormKeysToTitle['dateOfBirth']}
							description={dayjs(form.values.dateOfBirth).format('DD/MM/YYYY')}
						/>
						<ListItem
							title={FormKeysToTitle['gender']}
							description={form.values.gender}
						/>
						<ListItem
							title={FormKeysToTitle['country']}
							description={form.values.country}
						/>
						<ListItem
							title={FormKeysToTitle['state']}
							description={form.values.state}
						/>
						<ListItem
							title={FormKeysToTitle['city']}
							description={form.values.city}
						/>
						<ListItem
							title={FormKeysToTitle['community']}
							description={form.values.community}
						/>
						<ListItem
							title={FormKeysToTitle['address']}
							description={form.values.address}
						/>
						<ListItem
							title={FormKeysToTitle['language']}
							description={form.values.language.join(', ')}
						/>
						<ListItem
							title={FormKeysToTitle['mobile']}
							description={form.values.mobile}
						/>
					</SimpleGrid>
				</Stepper.Completed>
			</Stepper>

			<Group position="right" mt="xl">
				{active !== 0 && (
					<Button variant="default" onClick={prevStep}>
						{t('tr.back')}
					</Button>
				)}
				{active !== 3 && (
					<Button onClick={nextStep}>{active === 2 ? 'Submit' : 'Next'}</Button>
				)}
			</Group>
		</Container>
	);
};

const StepOne = ({
	form,
}: {
	form: UseFormReturnType<ICompleteProfileRequest>;
}) => {
	const { t } = useTranslation(['default']);
	const initialYear = new Date().getFullYear() - 18;

	const handleSetPhone = (phone: string) => {
		form.values.mobile = phone;
	};

	const genders = [
		t('tr.male'),
		t('tr.female'),
		t('tr.non-binary'),
		t('tr.prefer-not-to-say'),
	];

	return (
		<Box>
			<Title
				mb={'sm'}
				color={mantineConfig.mantine.input.label.color}
				size={mantineConfig.mantine.input.label.fontSize}
				weight={mantineConfig.mantine.input.label.fontWeight}
			>
				{t('tr.gender').toUpperCase()} <span style={{ color: 'red' }}>*</span>
			</Title>
			<Select
				mb={'sm'}
				data={genders}
				size={mantineConfig.mantine.input.size}
				placeholder={`${t('tr.select-your-gender')}`}
				withAsterisk={true}
				required={true}
				{...form.getInputProps('gender')}
			/>

			<Title
				color={mantineConfig.mantine.input.label.color}
				size={mantineConfig.mantine.input.label.fontSize}
				weight={mantineConfig.mantine.input.label.fontWeight}
				mb={'sm'}
			>
				{t('tr.your-title').toUpperCase()}{' '}
				<span style={{ color: 'red' }}>*</span>
			</Title>
			<Select
				mb={'sm'}
				size={mantineConfig.mantine.input.size}
				required={true}
				placeholder={`${t('tr.choose-your-title')}`}
				data={titles.map((option) => ({
					value: option,
					label: `${t(`tr.${option}`)}`,
				}))}
				{...form.getInputProps('title')}
			/>
			<Title
				mb={'sm'}
				color={mantineConfig.mantine.input.label.color}
				size={mantineConfig.mantine.input.label.fontSize}
				weight={mantineConfig.mantine.input.label.fontWeight}
			>
				{t('tr.first-name').toUpperCase()}{' '}
				<span style={{ color: 'red' }}>*</span>
			</Title>
			<TextInput
				size={mantineConfig.mantine.input.size}
				mb={'sm'}
				withAsterisk={true}
				placeholder={`${t('tr.your-first-name')}`}
				required={true}
				{...form.getInputProps('firstName')}
			/>

			<Title
				mb={'sm'}
				color={mantineConfig.mantine.input.label.color}
				size={mantineConfig.mantine.input.label.fontSize}
				weight={mantineConfig.mantine.input.label.fontWeight}
			>
				{t('tr.last-name').toUpperCase()}{' '}
				<span style={{ color: 'red' }}>*</span>
			</Title>
			<TextInput
				mb={'sm'}
				size={mantineConfig.mantine.input.size}
				withAsterisk={true}
				placeholder={`${t('tr.your-last-name')}`}
				required={true}
				{...form.getInputProps('surName')}
			/>

			<div>
				<PhoneInputMantine
					disable={false}
					value={form.values.mobile}
					handleSetPhone={handleSetPhone}
				/>
			</div>

			<Title
				style={{ marginTop: '15px' }}
				mb={'sm'}
				color={mantineConfig.mantine.input.label.color}
				size={mantineConfig.mantine.input.label.fontSize}
				weight={mantineConfig.mantine.input.label.fontWeight}
			>
				{t('tr.date-of-birth').toUpperCase()}{' '}
				<span style={{ color: 'red' }}>*</span>
			</Title>
			<DatePickerInput
				size={mantineConfig.mantine.input.size}
				clearable={true}
				defaultLevel="decade"
				placeholder={'YYYY-MM-DD'}
				mb={'sm'}
				maxDate={new Date(initialYear, 12)}
				required={true}
				icon={<IconCalendar size={16} />}
				{...form.getInputProps('dateOfBirth')}
			/>
			<Title
				mb={'sm'}
				color={mantineConfig.mantine.input.label.color}
				size={mantineConfig.mantine.input.label.fontSize}
				weight={mantineConfig.mantine.input.label.fontWeight}
			>
				{t('tr.referall-code-optional').toUpperCase()}{' '}
			</Title>
			<TextInput
				size={mantineConfig.mantine.input.size}
				mb={'sm'}
				withAsterisk={true}
				placeholder={`${t('tr.referall-code-optional')}`}
				required={true}
				{...form.getInputProps('referallCode')}
			/>
		</Box>
	);
};

// 🟢 Step Two (Auto-detect Country, State, City using Geolocation)
const StepTwo = ({
	form,
}: {
	form: UseFormReturnType<ICompleteProfileRequest>;
}) => {
	const { t } = useTranslation(['default']);
	const [loading, setLoading] = useState(false);
	const [languages, setLanguages] = useState<string[]>([]);

	useEffect(() => {
		if (!navigator.geolocation) {
			console.error('Geolocation is not supported by your browser.');
			showNotification({
				title: 'Error',
				message: 'Geolocation is not supported by your browser.',
				color: 'red',
			});
			return;
		}

		console.log('Requesting geolocation...');
		setLoading(true);

		navigator.geolocation.getCurrentPosition(
			async (position) => {
				const { latitude, longitude } = position.coords;
				console.log(
					`User's Coordinates: Latitude=${latitude}, Longitude=${longitude}`
				);

				try {
					const response = await fetch(
						`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
					);
					const data = await response.json();
					console.log('Fetched Location Data:', data);

					if (data && data.address) {
						const country = data.address.country || 'Not Found';
						const state =
							data.address.state || data.address.region || data.address.county;
						const city =
							data.address.city ||
							data.address.town ||
							data.address.village ||
							'Not Found';

						console.log(
							`Detected Country: ${country}, State: ${state}, City: ${city}`
						);

						await fetchCountryDetails(country);
						form.setValues({
							country,
							state,
							city
						});
					} else {
						throw new Error('No address data found.');
					}
				} catch (error) {
					console.error('Error fetching geolocation data:', error);
					showNotification({
						title: 'Error',
						message: 'Failed to fetch location data. Please enter manually.',
						color: 'red',
					});
				}
				setLoading(false);
			},
			(error) => {
				console.error('Geolocation error:', error);
				showNotification({
					title: 'Error',
					message: 'Location access denied. Please enter manually.',
					color: 'red',
				});
				setLoading(false);
			}
		);
	}, []);

	const fetchCountryDetails = async (country: string): Promise<void> => {
		try {
			const response = await fetch(
				`https://restcountries.com/v3.1/name/${country}`
			);
			const data = await response.json();
			console.log(`Country Data for ${country}:`, data);

			if (data && data[0]) {
				const countryInfo = data[0];

				// Extract Languages
				const countryLanguages = countryInfo.languages
					? Object.values(countryInfo.languages)
					: ['English'];
				setLanguages(countryLanguages as string[]);

				// Extract Currency Code (e.g., "INR" instead of "Indian Rupee")
				const currencyCode = countryInfo.currencies
					? Object.keys(countryInfo.currencies)[0]
					: 'N/A';

				console.log(`Currency Code: ${currencyCode}`);

				// Store currency code in the form
				form.setValues({ currency: currencyCode });
			} else {
				setLanguages(['English']);
				form.setFieldValue('currency', 'N/A');
			}
		} catch (error) {
			console.error('Error fetching country details:', error);
			setLanguages(['English']);
			form.setFieldValue('currency', 'N/A');
		}
	};

	return (
		<Box>
			{/* Country Field */}
			<Title
				mb={'sm'}
				color={mantineConfig.mantine.input.label.color}
				size={mantineConfig.mantine.input.label.fontSize}
				weight={mantineConfig.mantine.input.label.fontWeight}
			>
				{t('tr.country').toUpperCase()} <span style={{ color: 'red' }}>*</span>
			</Title>
			<Select
				data={[form.values.country]}
				required
				withAsterisk
				mb="sm"
				size={mantineConfig.mantine.input.size}
				value={form.values.country}
				onChange={(value) => form.setFieldValue('country', value)}
			/>

			{/* State Field */}
			<Title
				mb={'sm'}
				color={mantineConfig.mantine.input.label.color}
				size={mantineConfig.mantine.input.label.fontSize}
				weight={mantineConfig.mantine.input.label.fontWeight}
			>
				{t('tr.state').toUpperCase()} <span style={{ color: 'red' }}>*</span>
			</Title>
			<Select
				data={[form.values.state]}
				required
				withAsterisk
				mb="sm"
				size={mantineConfig.mantine.input.size}
				value={form.values.state}
				onChange={(value) => form.setFieldValue('state', value)}
			/>

			{/* City Field */}
			<Title
				mb={'sm'}
				color={mantineConfig.mantine.input.label.color}
				size={mantineConfig.mantine.input.label.fontSize}
				weight={mantineConfig.mantine.input.label.fontWeight}
			>
				{t('tr.city').toUpperCase()} <span style={{ color: 'red' }}>*</span>
			</Title>
			<Select
				data={[form.values.city]}
				required
				withAsterisk
				mb="sm"
				size={mantineConfig.mantine.input.size}
				value={form.values.city}
				onChange={(value) => form.setFieldValue('city', value)}
			/>

			{/* Language Field */}
			<Title
				mb={'sm'}
				color={mantineConfig.mantine.input.label.color}
				size={mantineConfig.mantine.input.label.fontSize}
				weight={mantineConfig.mantine.input.label.fontWeight}
			>
				{t('tr.language').toUpperCase()} <span style={{ color: 'red' }}>*</span>
			</Title>
			<MultiSelect
				data={languages}
				required
				withAsterisk
				mb="sm"
				size={mantineConfig.mantine.input.size}
				value={form.values.language}
				onChange={(values) => form.setFieldValue('language', values)}
			/>

			{/* Address Field */}
			<Title
				mb={'sm'}
				color={mantineConfig.mantine.input.label.color}
				size={mantineConfig.mantine.input.label.fontSize}
				weight={mantineConfig.mantine.input.label.fontWeight}
			>
				{t('tr.address').toUpperCase()} <span style={{ color: 'red' }}>*</span>
			</Title>
			<TextInput
				placeholder={`${t('tr.your-address')}`}
				required
				mb="sm"
				size={mantineConfig.mantine.input.size}
				{...form.getInputProps('address')}
			/>
			<Title
				color={mantineConfig.mantine.input.label.color}
				size={mantineConfig.mantine.input.label.fontSize}
				weight={mantineConfig.mantine.input.label.fontWeight}
				mb={'sm'}
			>
				{t('tr.local-government-area').toUpperCase()}{' '}
				<span style={{ color: 'red' }}>*</span>
			</Title>
			<TextInput
				required={true}
				mb={'sm'}
				withAsterisk={true}
				placeholder={`${t('tr.provide-your-local-government-area')}`}
				{...form.getInputProps('community')}
			/>
			<Title
				color={mantineConfig.mantine.input.label.color}
				size={mantineConfig.mantine.input.label.fontSize}
				weight={mantineConfig.mantine.input.label.fontWeight}
				mb={'sm'}
			>
				{t('Currency').toUpperCase()} <span style={{ color: 'red' }}>*</span>
			</Title>
			<TextInput
				value={form.values.currency}
				disabled
				mb="sm"
				size={mantineConfig.mantine.input.size}
			/>

			{loading && <p>Fetching location data...</p>}
		</Box>
	);
};
