// import { useEffect, useState } from 'react';
// import { Card, Text, Modal, Group, Title, Loader, Container } from '@mantine/core';
// import { monoBaseApi } from '../../api';

// interface Appointment {
// 	id: number;
// 	appointmentTime: string;
// 	appointedDoctorEmail: string;
// 	doctorFullName: string;
// }
// const monoApi = monoBaseApi(); // mono api

// export const getAppointments = async (patientUserId: number) => {
// 	try {
// 		const { data } = await monoApi.get(
// 			`/api/v1/consultations/get-appointed-consultations`,
// 			{
// 				params: { patientUserId }, // Send as request param
// 			}
// 		);
// 		// Convert appointmentTime to readable format
// 		return data.map((appointment: Appointment) => ({
// 			...appointment,
// 			appointmentTime: new Date(appointment.appointmentTime).toLocaleString(),
// 		}));
// 	} catch (error) {
// 		console.error('Error fetching appointments:', error);
// 		throw error;
// 	}
// };

// export const Appointments: React.FC<{ patientUserId: number }> = ({
// 	patientUserId,
// }) => {
// 	const [appointments, setAppointments] = useState<Appointment[]>([]);
// 	const [selectedAppointment, setSelectedAppointment] = useState<Appointment | null>(null);
// 	const [modalOpen, setModalOpen] = useState(false);
// 	const [loading, setLoading] = useState(true);

// 	useEffect(() => {
// 		const fetchAppointments = async () => {
// 			try {
// 				setLoading(true);
// 				const response = await getAppointments(patientUserId);
// 				setAppointments(response);
// 			} catch (error) {
// 				console.error('Error fetching appointments:', error);
// 			} finally {
// 				setLoading(false);
// 			}
// 		};

// 		if (patientUserId) {
// 			fetchAppointments();
// 		}
// 	}, [patientUserId]);

// 	return (
// 		<Container>
// 			<Title order={2} align="center" mb="lg">
// 				Appointments
// 			</Title>
// 			{loading ? (
// 				<Group position="center">
// 					<Loader size="lg" />
// 				</Group>
// 			) : appointments.length === 0 ? (
// 				<Text align="center">No appointments found.</Text>
// 			) : (
// 				<Group position="center" spacing="lg" style={{ flexWrap: 'wrap' }}>
// 					{appointments.map((appointment) => (
// 						<Card
// 							key={appointment.id}
// 							shadow="sm"
// 							padding="lg"
// 							radius="md"
// 							onClick={() => {
// 								setSelectedAppointment(appointment);
// 								setModalOpen(true);
// 							}}
// 							style={{ cursor: 'pointer', width: '280px', transition: 'transform 0.2s', }}
// 							onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
// 							onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
// 						>
// 							<Text weight={600} size="lg" color="blue">
// 								{appointment.doctorFullName || 'Unknown Doctor'}
// 							</Text>
// 							<Text size="sm" color="dimmed">{appointment.appointmentTime}</Text>
// 							<Text size="sm">Email: {appointment.appointedDoctorEmail}</Text>
// 						</Card>
// 					))}
// 				</Group>
// 			)}

// 			{/* Modal for Appointment Details */}
// 			<Modal
// 				opened={modalOpen}
// 				onClose={() => setModalOpen(false)}
// 				title="Appointment Details"
// 			>
// 				{selectedAppointment && (
// 					<div>
// 						<Text size="lg" weight={500}>Appointment Time:</Text>
// 						<Text>{selectedAppointment.appointmentTime}</Text>
// 						<Text size="lg" weight={500} mt="sm">Doctor Email:</Text>
// 						<Text>{selectedAppointment.appointedDoctorEmail}</Text>
// 					</div>
// 				)}
// 			</Modal>
// 		</Container>
// 	);
// };



import { useEffect, useState } from 'react';
import { Card, Text, Modal, Group, Title, Loader, Container, Button } from '@mantine/core';
import { monoBaseApi } from '../../api';

interface Appointment {
  id: number;
  appointmentTime: string;
  appointedDoctorEmail: string;
  doctorFullName: string;
}
const monoApi = monoBaseApi(); // mono api

export const getAppointments = async (patientUserId: number) => {
  try {
    const { data } = await monoApi.get(
      `/api/v1/consultations/get-appointed-consultations`,
      {
        params: { patientUserId }, // Send as request param
      }
    );
    return data.map((appointment: Appointment) => {
      const dateObj = new Date(appointment.appointmentTime);
      return {
        ...appointment,
        date: dateObj.toLocaleDateString(),
        time: dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      };
    });
  } catch (error) {
    console.error('Error fetching appointments:', error);
    throw error;
  }
};

export const Appointments: React.FC<{ patientUserId: number }> = ({ patientUserId }) => {
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [selectedAppointment, setSelectedAppointment] = useState<Appointment | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        setLoading(true);
        const response = await getAppointments(patientUserId);
        setAppointments(response);
      } catch (error) {
        console.error('Error fetching appointments:', error);
      } finally {
        setLoading(false);
      }
    };
    if (patientUserId) {
      fetchAppointments();
    }
  }, [patientUserId]);

  return (
    <Container>
      {loading ? (
        <Group position="center">
          <Loader size="lg" />
        </Group>
      ) : appointments.length === 0 ? (
        <Text align="center">No appointments found.</Text>
      ) : (
        <Group position="center" spacing="lg" style={{ flexWrap: 'wrap' }}>
          {appointments.map((appointment) => (
            <Card key={appointment.id} shadow="sm" padding="lg" radius="md" style={{ width: '280px' }}>
              <Text weight={600} size="md" align="center">{appointment.doctorFullName || 'Unknown Doctor'}</Text>
              <Text size="sm" color="dimmed" align="center" mt="xs">{appointment.date}</Text>
              <Text size="sm" color="dimmed" align="center">{appointment.time}</Text>
              <Button
                fullWidth mt="md"
                color="teal"
                onClick={() => {
                  setSelectedAppointment(appointment);
                  setModalOpen(true);
                }}
              >
                View Details
              </Button>
            </Card>
          ))}
        </Group>
      )}
      <Modal opened={modalOpen} onClose={() => setModalOpen(false)} title="Appointment Details">
        {selectedAppointment && (
          <div>
            <Text size="lg" weight={500}>Appointment Time:</Text>
            <Text>{selectedAppointment.date} - {selectedAppointment.time}</Text>
            <Text size="lg" weight={500} mt="sm">Doctor Email:</Text>
            <Text>{selectedAppointment.appointedDoctorEmail}</Text>
          </div>
        )}
      </Modal>
    </Container>
  );
};
