import { MutableRefObject, useRef } from "react";

// Global reference for storing the total amount
const totalAmountRef: MutableRefObject<number | null> = { current: null };

// Function to set the total amount
export const setGlobalTotalAmount = (amount: number) => {
	totalAmountRef.current = amount;
};

// Function to get the total amount
export const getGlobalTotalAmount = () => {
	return totalAmountRef.current;
};
