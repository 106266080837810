import { Grid, SimpleGrid, Space } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import Styles from '../../assets/styles/pages/home.module.css';
import PageTitle from '../../components/core/page-title';
import NavCard from '../../components/core/nav-card';
import Container from '../../components/layout/container';
import { AvailableRoutes } from '../navigation';

interface IServices {
  title: string;
  path: AvailableRoutes;
  imageName: string;
}

const services: IServices[] = [
  {
    title: 'tr.consultation-history',
    path: 'consultations',
    imageName: 'medical_history',
  },
  {
    title: 'tr.drug-prescriptions',
    path: 'prescriptions',
    imageName: 'consultation_prescription',
  },
  {
    title: 'tr.follow-up',
    path: 'followup',
    imageName: 'consultation_followup',
  },
  {
    title: 'tr.invitation',
    path: 'invitations',
    imageName: 'invite_friend_and_family',
  },
  {
    title: 'Medical Report',
    path: 'report',
    imageName: 'medical_history'
  },
  {
    title: 'Appointments',
    path: 'appointments',
    imageName: 'consultation_prescription'
  }
];

export const Services = () => {
  const { t } = useTranslation(['default']);
  return (
    <Container>
      <PageTitle heading={`${t('tr.service').toUpperCase()}`} />
      <Space h="md" />
      <Grid className={Styles['flex-container-one']}>
        <SimpleGrid
          cols={2}
          spacing="xl"
          breakpoints={[
            { maxWidth: 900, cols: 2, spacing: 'md' },
            { maxWidth: 625, cols: 1, spacing: 'md' },
          ]}
        >
          {services?.map((item) => (
            <NavCard
              key={item.path}
              title={t(item.title)}
              path={item.path}
              imageName={item.imageName}
            />
          ))}
        </SimpleGrid>
      </Grid>
    </Container>
  );
};
